import React, { useState, useEffect } from "react";
import styled, { ThemeProvider, StyledComponent } from "styled-components";
import { Link } from "gatsby";
import {
  EntryFeaturedImageInterface,
  EntryInterface,
} from "../../types/entries";
import {
  ContentBlock,
  ContentBlockHandle,
} from "../content-blocks/content-layout/types";
import { readableDateSegments } from "../../utils/dates/readable-date";
import { Tag } from "../tag";
import { EntryListsInterface } from "./types";
import { TextBlockInterface } from "../content-blocks/text-block/types";

const EntryContainer = styled.div`
  margin-top: 2rem;
`;

const StyledEntry: StyledComponent<"div", any> = styled.div`
  --callout-width: 50px;

  margin: 0 0 3rem;
  display: grid;
  grid-template-columns: var(--callout-width) auto;
  grid-template-areas: "date post";
  grid-column-gap: ${(props) =>
    props.theme.leftAlignContent ? "2rem" : "1rem"};
  margin-left: ${(props) =>
    props.theme.leftAlignContent && "calc(0px - var(--callout-width) - 2rem)"};
`;

const StyledEntryTitle = styled.h4`
  grid-column: post;
  margin: 0;
  line-height: 1;

  a {
    text-decoration: none;
    color: #333;
  }
`;
const StyledEntryDate = styled.span`
  grid-column: date;
  grid-row: 1 / span 3;
  text-align: center;
`;
const StyledDay = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.2;
  color: #666;
`;
const StyledMonth = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1;
  color: #666;
`;
const StyledYear = styled.div`
  font-size: 1rem;
  line-height: 1;
  color: #666;
`;
const StyledEntryTagWrapper = styled.div`
  grid-column: post;
  margin: 0.5rem 0;
`;
const StyledEntryTags = styled(Tag)``;
const StyledEntryLink = styled(Link)`
  grid-column: post;
  display: block;
  /* font-size: 1rem; */
  font-weight: bold;
  line-height: 1;
  text-decoration: none;
  color: var(--brand);
  background: white;
`;

const EntryDate = ({ postDate }: { postDate: string }) => {
  const dateSegments = readableDateSegments(postDate);

  return (
    <StyledEntryDate>
      <StyledMonth>{dateSegments.month}</StyledMonth>
      <StyledDay>{dateSegments.day}</StyledDay>
      <StyledYear>{dateSegments.year}</StyledYear>
    </StyledEntryDate>
  );
};

const StyledEntryThumb = styled.div`
  width: var(--callout-width);
  height: var(--callout-width);
  grid-row: 1 / span 3;
  overflow: hidden;
  border-radius: 50%;
  background-color: ${(props) => props.theme.featuredBackgroundColor ?? "#aaa"};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--font-family-heading);
`;

const StyledEntryImage = styled.img`
  height: calc(var(--callout-width) * 1.15);
  width: auto;
`;

const StyledEntryThumbLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
`;

const EntryThumb = ({
  featuredImage,
  featuredBackgroundColor,
  title,
  uri,
}: {
  featuredImage?: EntryFeaturedImageInterface[];
  featuredBackgroundColor?: string;
  title: string;
  uri: string;
}) => {
  let image = null;

  if (featuredImage && featuredImage.length) {
    image = featuredImage[0];
  }
  return (
    <ThemeProvider theme={{ featuredBackgroundColor }}>
      <StyledEntryThumb>
        <StyledEntryThumbLink to={`/${uri}`}>
          {!image && title[0]}
          {image && (
            <StyledEntryImage
              src={image.small}
              alt={image?.mediaAltText ?? `Thumbnail for ${title}`}
            />
          )}
        </StyledEntryThumbLink>
      </StyledEntryThumb>
    </ThemeProvider>
  );
};

const StyledEntrySnippet = styled.div`
  + a {
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
`;

const EntrySnippet = ({
  contentEditorFields,
}: {
  contentEditorFields: ContentBlock[];
}) => {
  const textBlocks = contentEditorFields.filter(
    (block) => block.typeHandle === ContentBlockHandle.TEXT
  );

  const [snippetText, setSnippetText] = useState("");

  useEffect(() => {
    if (textBlocks && textBlocks.length) {
      const { richTextContent } = textBlocks[0] as TextBlockInterface;
      const tempTextNode = document.createElement("div");
      tempTextNode.innerHTML = richTextContent;
      const newSnippetText = tempTextNode.innerText.substr(0, 128).trimRight();
      setSnippetText(`${newSnippetText}...`);
    }
  });

  return <StyledEntrySnippet>{snippetText}</StyledEntrySnippet>;
};

const getViewMoreLabel = (sectionHandle: string) => {
  switch (sectionHandle) {
    case "projects":
      return "Project";
    case "articles":
      return "Article";
    default:
      return "More";
  }
};

const buildEntryListLinks = (
  items: EntryInterface[],
  imageThumb?: boolean,
  showSnippet?: boolean
) =>
  items.map((item: EntryInterface) => {
    const {
      title,
      uri,
      uid,
      featuredBackgroundColor,
      featuredImage,
      contentEditorFields,
      postDate,
      entryTags,
      sectionHandle,
    } = item;

    let viewMoreLabel = getViewMoreLabel(sectionHandle);

    return (
      <StyledEntry key={uid}>
        <StyledEntryTitle>
          <Link to={`/${uri}`}>{title}</Link>
        </StyledEntryTitle>
        {imageThumb ? (
          <EntryThumb
            uri={uri}
            title={title}
            featuredImage={featuredImage}
            featuredBackgroundColor={featuredBackgroundColor}
          />
        ) : (
          <EntryDate postDate={postDate} />
        )}
        <StyledEntryTagWrapper>
          {entryTags.map((tag) => (
            <StyledEntryTags key={tag.uid} url={`/tags/${tag.slug}`}>
              {tag.title}
            </StyledEntryTags>
          ))}
        </StyledEntryTagWrapper>
        {showSnippet && (
          <EntrySnippet contentEditorFields={contentEditorFields} />
        )}
        <StyledEntryLink to={`/${uri}`}>View {viewMoreLabel}</StyledEntryLink>
      </StyledEntry>
    );
  });

export const EntryLists: React.FunctionComponent<EntryListsInterface> = (
  props: EntryListsInterface
) => {
  const { entries, imageThumb, showSnippet, leftAlignContent } = props;
  const articleLinks = buildEntryListLinks(entries, imageThumb, showSnippet);

  return (
    <EntryContainer>
      <ThemeProvider theme={{ leftAlignContent }}>{articleLinks}</ThemeProvider>
    </EntryContainer>
  );
};

EntryLists.defaultProps = {
  imageThumb: false,
  showSnippet: false,
  leftAlignContent: false,
};
